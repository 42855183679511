@import "variables";
// Bootstrap
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/normalize";
@import "../../node_modules/bootstrap/scss/print";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/type";
@import "../../node_modules/bootstrap/scss/images";
@import "../../node_modules/bootstrap/scss/code";
@import "../../node_modules/bootstrap/scss/tables";
@import "../../node_modules/bootstrap/scss/forms";
@import "../../node_modules/bootstrap/scss/custom-forms";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/transitions";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/input-group";
@import "../../node_modules/bootstrap/scss/breadcrumb";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/media";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/grid";
@import "../../node_modules/bootstrap/scss/close";
@import "../../node_modules/bootstrap/scss/nav";
@import "../../node_modules/bootstrap/scss/responsive-embed";
@import "../../node_modules/bootstrap/scss/progress";
@import "../../node_modules/bootstrap/scss/popover";
@import "../../node_modules/bootstrap/scss/modal";
@import "../../node_modules/bootstrap/scss/tooltip";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/card";
@import "../../node_modules/bootstrap/scss/jumbotron";

// Other
@import "../../node_modules/slick-carousel/slick/slick.scss";
@import "../../node_modules/sweetalert2/src/sweetalert2.scss";
@import "content/_card.scss";
@import "content/_flipclock.scss";
@import "../../node_modules/easy-autocomplete/src/sass/easy-autocomplete";
@import "../../node_modules/easy-autocomplete/src/sass/easy-autocomplete.themes";


// Base
@import "base/sass-space";
@import "base/mixins";
@import "base/helper-class";
@import "base/bootstrap-ext";
// Fonts
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700|Damion&subset=latin-ext');
@import "../fonts/icomoon/style.scss";
// Custom
@import "../_modules/header/header";
@import "../_modules/footer/footer";
@import "../_modules/form/form";
@import "content/aside";
@import "content/pagination";
@import "content/table";
@import "content/package";
@import "content/steps";
@import "content/payment";
@import "content/jw-skin";
@import "content/swal";


/* ===========================================
 * General styles
 * ======================================== */
html {
    font-size: 13px;

    @include media-breakpoint-up(sm) {
        font-size: 14px;
    }

    @include media-breakpoint-up(md) {
        font-size: 15px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 16px;
    }

    @include media-breakpoint-up(xl) {
        font-size: 16px;
    }
}

body {
    font-weight: 300;

    &:not(.modal-open) {
        padding-right: 0 !important;
    }
}

a {
    @include transition(color $transition-speed);
}

img {
    max-width: 100%;
}

/* ===========================================
 * Layout
 * ======================================== */

#wrapper {
    overflow: hidden;
}

.col-two {
    #container {
        @extend .container;
        display: flex;
        flex-wrap: wrap;

        #left,
        #main {
            width: 100%;
            margin-bottom: 1rem;
        }

        #main {
            order: 2;
        }

        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;

            #main {
                padding-left: 6px;
                flex: 0 0 75.2%;
                max-width: 75.2%;
            }

            #left {
                flex: 0 0 24.8%;
                width: 24.8%;
                min-width: 190px;
                padding-right: 6px;
            }
        }
    }
}

.col-two-right {
    #container {
        @extend .container;
        padding-top: 2.2rem;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        #main,
        #right {
            @extend %panel;
            width: 100%;
            padding: 1.4rem 15px;
            margin-bottom: 2rem;
        }

        @include media-breakpoint-up(md) {
            flex-wrap: nowrap;
            align-items: flex-start;

            #right,
            #main {
                padding-left: 1.7rem;
                padding-right: 1.7rem;
            }

            #main {
                flex: auto;
                width: auto;
                padding: 2rem;
            }

            #right {
                flex: 0 0 32.7%;
                width: 32.7%;
                margin-left: 10px;
                display: flex;
                flex-direction: column;
            }
        }

        @include media-breakpoint-up(lg) {
            #main {
                flex: auto;
                width: auto;
                padding: 2.8rem 4rem;
            }
        }
    }
}

.aside-image-down {
    margin-top: auto;
    margin-bottom: -1.4rem;
    text-align: center;
}

/* ===========================================
 * Home > Section
 * ======================================== */
.section {
    padding-top: 3rem;
    padding-bottom: 3rem;
    line-height: 1.7;

    &__headline {
        font-size: 2.625rem;
        line-height: 1.19;
        margin-bottom: 2rem;

        @include media-breakpoint-down(md) {
            font-size: 2rem;
        }
    }

    &__subheadline {
        color: #8791b3;
        letter-spacing: 0.03125rem;
    }

    &__icon {
        font-size: 2.5rem;
        color: $brand-primary;
        margin-bottom: .8rem;
    }
}

.section-img-right {
    position: relative;
    right: -15px;
    max-width:180%;

    @include media-breakpoint-down(md) {
        max-width: 130%;
    }
}

/* ===========================================
 * Home > Boxes
 * ======================================== */
.boxes-home {
    @extend %panel;
    display: flex;
    flex-wrap: wrap;
    margin-top: -12rem;
    line-height: 1.45;

    &__box {
        text-align: center;
        padding: 2rem 1rem 4rem 1rem;
        width: 100%;
        font-weight: 300;
        font-size: 14px;
        color: #6d7e8e;

        &:not(:first-child) {
            border: solid $gray-lighter;
            border-width: 1px 0 0 0;
        }

        &-icon {
            height: 160px;
            line-height: 160px;
            padding-left: 3rem;
            padding-right: 3rem;
        }

        &-headline {
            font-weight: 600;
            font-size: 1.3125rem;
            color: $text-color;
            line-height: 1.05;
            margin-bottom: 1.3rem;
        }

        &-text {
            max-width: 200px;
            margin: auto;
            line-height: 1.4;
        }

        @include media-breakpoint-up(md) {
            width: 33.3333%;
            &:not(:first-child) {
                border-width: 0 0 0 1px;
            }
        }

        @include media-breakpoint-up(lg) {
            padding-top: 3.7rem;
            padding-bottom: 6.3rem;
        }
    }
}

/* ===========================================
 * Search > File List
 * ======================================== */
.file {
    $this: &;
    @extend %panel;
    border-radius: 4px;
    margin-bottom: 1rem;
    display: flex;
    flex-wrap: wrap;
    font-size: 14px;
    font-weight: normal;

    label {
        margin-bottom: 0;
    }

    > * {
        display: flex;
        align-items: center;
        padding: 9px 1rem;
        flex-grow: 1;
        max-width: 100%;
        border: 0 solid $gray-lighter;
        border-width: 0 0 0 1px;
    }

    &__name {
        border-width: 0;
        overflow: hidden;
        word-break: break-all;
        color: #353d59;
        .custom-control-description,
        .custom-control {
            max-width: 100%;
        }

        span {
            display: block;
        }

        &-text {
            line-height: 1.2;
        }

        &-text-small {
            color: $orange;
            font-size: 12px;

            a {
                @include link-variant(inherit);
            }
        }
    }

    > *:not(.file__name) {
        justify-content: center;
        font-weight: 600;
        color: #79829f;
    }

    &__data {
        background-color: #f7f8fb;

        i {
            margin-right: 10px;
            font-size: 12px;
            color: #c1c7db;
        }
    }

    &__play {
        color: $orange;
        text-transform: uppercase;

        a {
            @include link-variant($orange);
        }

        i {
            margin-right: 7px;
            font-size: 12px;
        }
    }

    @include media-breakpoint-down(md) {
        font-size: 12px;

        &__name {
            flex: 0 0 calc(100% - 158px);
            max-width: calc(100% - 158px);
            padding-right: 5px;
        }

        &__download,
        &__play {
            flex: 0 0 110px;
            max-width: 110px;
        }

        > * {
            border-width: 0;

            &#{$this}__name {
                border-width: 0 0 1px 0;
            }

            &#{$this}__download {
                border-width: 0 0 1px 1px;
            }
            &#{$this}__data,
            &#{$this}__play {
                border-width: 0 0 0 1px;
            }
        }
    }

    @media (max-width: 345px) {
        &__play {
            flex: 0 0 100%;
            max-width: 100%;
            border-width: 1px 0 0 0 !important;
        }
    }

    @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
        
        &__download {
            order: 1;
        }

        &__download {
            flex: 0 0 107px;
            max-width: 107px;
        }

        &__size {
            flex: 0 0 100px;
            max-width: 100px;
        }

        &__data,
        &__play {
            flex: 0 0 95px;
            max-width: 95px;
        }
    }
}

/* ===========================================
 * Section > Servers
 * ======================================== */
.section--info {
    font-size: 11px;
    color: #c1c7db;
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: #fafafc;
    position: relative;
    z-index: 1;

    &:after {
        content: '';
        position: absolute;
        width: 5000px;
        bottom: 0;
        top: -3rem;
        background-color: #fafafc;
        left: 50%;
        margin-left: -2500px;
        z-index: -1;
    }
}

/* ===========================================
 * Section > Servers
 * ======================================== */
.section--servers {
    background-color: #fafafc;
    padding-top: 4rem;
    padding-bottom: 8.5rem;
    position: relative;

    .section__headline {
        margin-bottom: 1rem;
    }
}

.section--servers-home {
    overflow: hidden;
    padding-bottom: 0;
    text-align: center;

    .section__headline {
        font-weight: normal;
        margin-bottom: 1rem;
    }
    .img-bg {
        width: 100%;
        position: relative;
        margin-bottom: -12.5%;
        @extend %disable-user-select;

    }

    .list-servers {
        padding-top: 2rem;

        @include media-breakpoint-up(lg) {
            flex-wrap: nowrap;
        }
    }
}

.list-servers {
    @extend %reset;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-left: -1rem;
    margin-right: -1rem;
    justify-content: center;

    > * {
        padding: 2rem 1rem;
        width: 50%;
        text-align: center;
    }

    @include media-breakpoint-up(sm) {
        > * {
            width: 25%;
        }
    }

    @include media-breakpoint-only(md) {
        > * {
            width: 33.333%;
        }
    }

    @include media-breakpoint-up(lg) {
        padding-left: 2rem;
        padding-top: 2.5rem;

        > * {
            padding-top: 1.8rem;
            padding-bottom: 1.8rem;
        }
    }
}

.col-two .section--servers {
    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: -5.5rem;
        background-color: #fafafc;
        z-index: -1;
    }
}

.col-two-right,
.one-col {
    .section--servers {
        background: transparent;
        margin-top: 6%;
    }
}

/* ===========================================
 * Box > Register
 * ======================================== */
.box-register,
.box-aside {
    text-align: center;
    margin-bottom: 2rem;

    .btn {
        min-width: 51%;
    }

    &__content {
        margin-bottom: 3rem;
        max-width: 280px;
        margin-left: auto;
        margin-right: auto;
    }
    .panel__headline {
        margin-bottom: 1rem;
    }
}

aside {
    .box-register__image {
        margin: -1.4rem -15px 5rem -15px;
        img {
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            margin-left: -1.7rem;
            margin-right: -1.7rem;
        }
    }
}

/* ===========================================
 * Testimonials
 * ======================================== */
.testimonials {
    margin-bottom: 3.7rem;
    font-weight: normal;

    &__item {
        display: flex;
        margin-bottom: 1.7rem;

    }

    &__avatar {
        text-align: center;
        font-weight: 600;
        flex: 0 0 67.5px;
        min-width: 67.5px;
        margin-right: 1.5rem;
        font-size: 14px;
        word-break: break-all;
        line-height: 1.3;

        img {
            width: 50px;
            margin-bottom: 5px;
        }
    }

    &__content {
        &-text {
            background-color: #fafafc;
            padding: 1.2rem 2rem 1.2rem 1.2rem;
            color: #878faa;
            line-height: 1.3;
            position: relative;

            &:before {
                content: '';
                position: absolute;
                right: 100%;
                top: 10px;
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 7.5px 10px 7.5px 0;
                border-color: transparent #fafafc transparent transparent;
            }
        }

        &-date {
            float: right;
            color: #a7acbd;
            font-size: 14px;
            padding-top: 7px;
        }
    }
}

// Testimonials home
.section--testimonials {
    padding-top: 3.3rem;
    text-align: center;
    margin-bottom: 8vh;

    .section__headline {
        margin-bottom: 1.2rem;
    }
}

.testimonials-bg {
    background: url(../images/chart.svg) no-repeat bottom;
    background-size: 100% 100%;
    padding: 2.8rem 0 2.5rem 0;
    margin: 3.5rem 0 4rem 0;

    &__container {
        padding-left: 15px;
        padding-right: 15px;
        max-width: 1400px;
        margin: 0 auto;
    }
}

.testimonials-home:not(.slick-initialized),
.testimonials-home .slick-track {
    display: flex;
    align-items: stretch;
    justify-content: space-between;

    > * {
        height: auto;

    }
}

.testimonials-home {
    $this: &;

    &__item {
        display: flex !important;
        align-items: center;
        justify-content: center;
        outline: none;
        position: relative;
        padding-bottom: 3.2rem;
        color: #c1c7db;
        text-align: center;


        .inside {
            @include transition(transform $transition-speed);
            transform: scale(.9);
            display: inline-block;
            margin: auto;
            max-width: 80%;
            background: #ffffff;
            text-align: center;
            padding: 2rem;
            box-shadow: 0 2px 42px 0 rgba(242, 242, 242, 0.39);
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                border-style: solid;
                border-width: 20px 15px 0 15px;
                border-color: #ffffff transparent transparent transparent;
                top: 100%;
                left: 50%;
                margin-left: -15px;
            }
        }

        img {
            display: inline-block;
            margin-bottom: 10px;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 4px;
            left: 50%;
            width: 19px;
            height: 19px;
            margin-left: -10px;
            background-color: #3585ff;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
            border: solid 3px #ffffff;
            border-radius: 50%;
        }

        &.slick-current {
            color: $navy-blue-light;


            .inside {
                margin: 0;
                max-width: 100%;
                transform: scale(1);
                cursor: inherit;
            }

            #{$this}__content-text {
                white-space: normal;
                max-width: none;
            }

            ~ .slick-current  {

                .inside {
                    margin: 0;
                    transform: scale(.9);
                }

                #{$this}__content-text {
                    white-space: nowrap;
                    max-width: none;

                }
            }
        }

    }

    &__avatar {
        font-weight: 600;
        color: #234170;
        margin-bottom: 1.1rem;
    }

    &__content {
        &-text {
            @include ellipsis(100%);
            @include transition(color $transition-speed);
            font-size: 13px;
            max-height: 32px;
            line-height: 1.3;
            margin-bottom: .8rem;
        }
        &-date {
            color: #3585ff;
            font-size: 13px;
        }
    }

    &__item--orange {
        #{$this}__content-date {
            color: #fe763a;
        }

        &:after {
            background-color: #fe763a;
        }
    }
}

/* ===========================================
 * Faq
 * ======================================== */
.faq {
    margin-bottom: 2.2rem;
    max-width: 750px;

    &__question {
        font-size: 1.75rem;
        letter-spacing: 0.0125rem;
        font-weight: normal;
        margin-bottom: 1rem;
        line-height: 1.25;
    }

    &__answer {
        position: relative;
        font-size: 14px;
        color: #878faa;
        padding-left: 25px;
        @include icon(before, '\e909');

        &:before {
            color: $orange;
            position: absolute;
            left: 2px;
            top: 5px;
            font-size: 12px;
        }
    }
}

/* ===========================================
 * List - box list
 * ======================================== */
.d-flex {
    width: 100%;
    align-items: stretch;
}

.panel__headline-container + .list-box-tab {
    margin-top: -1rem;
}

.list-box-tab {
    @extend %reset;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: -8px;
    margin-right: -8px;
    margin-bottom: 1.6rem;
    max-width: 1200px;

    > * {
        @extend %reset;

        padding: 8px;
        width: 50%;

        @include media-breakpoint-up(lg) {
            width: 25%;
        }
    }
}

.box-tab {
    $this: &;
    @extend %panel;
    display: block;
    height: 100%;
    position: relative;
    padding: 1rem;
    border-radius: 0;
    font-size: 0.875rem;
    font-weight: normal;
    @include link-variant($color-light);

    &:hover,
    &:focus {
        text-decoration: none;

        &__text {
            color: inherit;
            background: red;
        }
    }

    > * {
        display: block;
    }

    &__icon {
        height: 60px;
        margin-bottom: 5px;
    }

    &__headline {
        font-size: 1.25rem;
        color: $navy-blue;
        margin-bottom: .5rem;
        line-height: 1.1;
    }

    &.active {
        background-color: $brand-primary;
        color: #ffffff;

        #{$this}__headline {
            color: #ffffff;
        }
    }

    @include media-breakpoint-up(md) {
        padding: 1.1rem 1.5rem 1.3rem 1.5rem;
    }
}

.progress {
    align-items: center;
    height: $progress-height;
}

.progress-bar {
    display: flex;
    justify-content: center;
    align-items: center;
}

.progress-file {
  height: 1rem;
  
  > .progress-bar {
      height: 1rem;
  }
}

.language-dropdown {
    button {
        height: 40px;
    }
    
    .dropdown-toggle::after {
        margin-top: -0.6rem;
    }
}

.dropdown-menu {
    &.choose-language {
        img {
            margin-top: -3px;
        }
    }
}

.show {
    > .dropdown-menu {
        padding: 0.3rem 0;
        
        &.choose-server {
            display: inline-flex;
            top: -5px;
            left: 130px;

            @media (max-width: 470px) {
                top: -50px;
                left: 0;
            }

            > .dropdown-item {
                padding: 3px 1rem;
            }
        }
    }
}

.filename-td {
    max-width: 15px;
    word-break: break-all;
    
    &.full-width {
        max-width: 100%;
    }
}

.file-header__name {
    
}

.file-header__size {
    flex: 0 0 100px;
}

.file-header__download {
    flex: 0 0 100px;
}

.file-header__noop {
    flex: 0 0 300px;
}

.file-header__icon {
    display: inline-block;
    margin-right: 5px;
    font-size: 12px;
}

.easy-autocomplete-container {
    color: #353d59 !important;
    z-index: 101;
}

.btn-gradient {
    border-radius: 3px;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24), 0 0 2px 0 rgba(0, 0, 0, 0.12);
    border: 1px solid;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.06));
    
    &.btn-primary {
        background-image: linear-gradient(to bottom, $btn-primary-bg, darken($btn-primary-bg, 25%));
        &:hover {
            background-image: linear-gradient(to bottom, darken($btn-primary-bg, 15%), darken($btn-primary-bg, 25%));
        }
    }

    &.btn-secondary {
        background-image: linear-gradient(to bottom, $btn-secondary-bg, darken($btn-secondary-bg, 25%));
        &:hover {
            background-image: linear-gradient(to bottom, darken($btn-secondary-bg, 15%), darken($btn-secondary-bg, 25%));
        }
    }

    &.btn-info {
        background-image: linear-gradient(to bottom, $btn-info-bg, darken($btn-info-bg, 25%));
        &:hover {
            background-image: linear-gradient(to bottom, darken($btn-info-bg, 15%), darken($btn-info-bg, 25%));
        }
    }

    &.btn-success {
        background-image: linear-gradient(to bottom, $btn-success-bg, darken($btn-success-bg, 25%));
        &:hover {
            background-image: linear-gradient(to bottom, darken($btn-success-bg, 15%), darken($btn-success-bg, 25%));
        }
    }

    &.btn-warning {
        background-image: linear-gradient(to bottom, $btn-warning-bg, darken($btn-warning-bg, 25%));
        &:hover {
            background-image: linear-gradient(to bottom, darken($btn-warning-bg, 15%), darken($btn-warning-bg, 25%));
        }
    }

    &.btn-danger {
        background-image: linear-gradient(to bottom, $btn-danger-bg, darken($btn-danger-bg, 25%));
        &:hover {
            background-image: linear-gradient(to bottom, darken($btn-danger-bg, 15%), darken($btn-danger-bg, 25%));
        }
    }
}

.easy-autocomplete {
    input {
        padding: 0.9rem 1.2rem;
    }
}