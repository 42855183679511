// form styles
.form-input-icon {
    position: relative;

    i {
        position: absolute;
        top: 50%;
        height: 2rem;
        line-height: 2rem;
        margin-top: -1rem;
        width: 1.5rem;
        text-align: right;
        color: $gray-light;
    }

    > input {
        padding-left: 2rem;
    }
}

.input-group-addon {
    font-size: 14px;
    font-weight: 400;

    > a {
        display: block;
        padding: $input-padding-y $input-padding-x;
        margin: -$input-padding-y -$input-padding-x / 2;
        background-color: rgba(0, 0, 0, .02);
        @include link-variant($brand-primary);

        &[data-show-password] {
            min-width:90px;
            text-align: center;
        }
    }
}

.btn {
    i {
        position: relative;
        top: .05em;
    }

    i:last-child {
        padding-left: .7rem;
    }
    i:first-child {
        padding-right: .7rem;
    }
}


.form-control {
    &::placeholder {
        letter-spacing: normal;
        font-weight: 300;
    }
}
input[type="password"],
input.password {
    letter-spacing: .5rem;
}