// header styles
#header {
    margin-bottom: 2.2rem;
}
/* ===========================================
 * Alert in Header
 * ======================================== */
.alert-header {
    padding: 0 15px;
    margin: 0;
    text-align: center;
    border: none;

    .close {
        padding: 1rem;
        right: -1rem;
        top: -.7rem;
        font-size: 14px;
        font-weight: 100;
        color: #b7e38b;
        text-shadow: none;
    }

    &__inside {
        max-width: 1340px;
        margin: auto;
        padding: 1.3rem 0 1.4rem 8rem;
        position: relative;
        font-size: 1rem;
        
        img {
            height: 80px;
            float: left;
            margin-top: -25px;
        }

        @include media-breakpoint-down(xs) {
            padding-left: 0;

            &:before {
                display: none;
            }
        }
    }
}

/* ===========================================
 * Navbar
 * ======================================== */
.header-navbar {
    background: $navy-blue;
    color: #fff;
    padding: 1.65rem 15px 2rem 15px;
    position: relative;

    @include media-breakpoint-down(md) {
        padding-top: 1rem;
    }

    > .inside {
        max-width: 1370px;
        margin: 0 auto;
    }

    .col {
        position: static;
    }

    .icon-navicon {
        font-size: 28px;
    }

    .button-group {
        margin: 0 -.45rem;

        .btn {
            margin: 0 .45rem;
            padding-left: 1.35rem;
            padding-right: 1.35rem;
        }
    }

    .btn,
    input,
    button {
        height: 2.75rem;
        line-height: 2.75rem;
        padding-top: 0;
        padding-bottom: 0;
    }

    a:not(.btn) {
        @include link-variant(#fff, $navy-blue-light);

        &.active {
            color: $navy-blue-light;
        }
    }

    @include media-breakpoint-down(xs) {
        padding-bottom: 0;

        .header-navigation {
            padding-top: 15px;
        }

        .row {
            justify-content: space-between;
        }

        .col-search {
            min-width: 100%;
            padding-top: 15px;
        }
    }
}

/**
 * Navbar > Logo
 */
.header-logo {
    font-family: $font-family-serif;
    font-size: 2rem;
    line-height: 1;

    img {
        display: block;
    }
}

/**
 * Navbar > Navigation
 */
.header-navigation {
    ul, li {
        @extend %reset;
    }

    li {
        display: inline-block;
        padding: 0 1.2rem;
    }
}

// Navbar > Navigation > Mobile
@include media-breakpoint-down(lg) {
    .header-navigation {
        @include transition(max-height $transition-speed, box-shadow $transition-speed);
        background-color: $navy-blue;;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        z-index: 99;
        max-height: 0;
        overflow: hidden;

        li {
            display: block;
            padding: 0;

            a {
                text-transform: uppercase;
                font-weight: 600;
                padding: 1rem;
                display: block;
                border-top: 1px solid rgba(white, .1);
            }
        }
    }

    .is-navigation {
        header .icon-navicon {
            color: $navy-blue-light;
        }

        .header-navigation {
            max-height: 500px;
            box-shadow: 0 10px 10px rgba(0, 0, 0, .2);
        }
    }
}

/**
 * Navbar > Search
 */
.header-search {
    form {
        position: relative;

        input {
            padding: 0 45px 0 10px;
            width: 100%;
            border: none;
            background-color: #404967;
            color: #fff;
            font-size: 14px;

            &::placeholder {
                color: $navy-blue-light;
            }
        }

        button {
            @extend %reset;
            position: absolute;
            right: 0;
            top: 0;
            width: 45px;
            text-align: center;
            color: $navy-blue-light;
        }
    }

    @include media-breakpoint-up(xl) {
        max-width: 420px;
        margin-left: auto;
        margin-right: auto;
    }
}


/**
 * Navbar > User
 */
.header-user {
    $this: &;
    letter-spacing: .0125rem;

    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        width: 2.5rem;
        height: 2.5rem;
        border: 1px solid $navy-blue-light;
        line-height: 2.4rem;
        text-align: center;
        border-radius: 50%;
        margin-right: 1rem;
    }

    &__name {
        font-size: 14px;
    }

    &__logout {
        font-size: 13px;

        a {
            @include link-variant($navy-blue-light, #fff)
        }
    }

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        .media {
            min-width: 130px;
        }
        .media#{&}__transfer {
            min-width: 230px;
        }
    }

    @include media-breakpoint-down(md) {
        margin-bottom: 1rem;
        padding-bottom: 1rem;
        border-bottom: 1px solid rgba(255, 255, 255, .1);

        .media {
            align-items: center;

            .media-body {
                div {
                    padding-right: 5px;
                    display: inline-block;
                }
            }
        }
    }

    @include media-breakpoint-down(xs) {
        .media {
            .media-body {
                div {
                    display: block;
                }
            }
        }
    }
}

/* ===========================================
 * Header > Banner
 * ======================================== */

.banner {
    display: flex;
    align-items: center;
    margin-right: -2rem;
    margin-left: -2rem;
    width: calc(100% + 4rem);
    padding: 3rem 2rem 11rem 2rem;
    color: #ffffff;
    font-size: 2rem;
    line-height: 1.1875;
    background: url('../images/banner.svg') $navy-blue right center no-repeat;
    background-size: 50% auto;

    &__content {
        max-width: 565px;
        width: 70%;
    }

    &__headline {
        margin-bottom: 1.5rem;
        font-weight: normal;
    }

    &__subheadline {
        font-size: 1.2rem;
        color: $navy-blue-light;
        font-weight: 300;
    }

    &__video {
        margin-top: 2.8rem;
        @include link-variant(#ffffff, $brand-primary);
        display: flex;
        float: left;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        letter-spacing: 0.2px;
        text-align: center;
        color: #ffffff;
        text-transform: uppercase;

        > * {
            padding-right: 20px;
            display: inline-block;

        }


        i {
            font-size: 36px;
            color: $brand-primary;
            vertical-align: sub;
        }
    }

    @include media-breakpoint-up(md) {
        font-size: 3rem;
        background-position: right top;

        &__subheadline {
            font-size: 1.5rem;
        }
    }

    @include media-breakpoint-up(xl) {
        padding: 4rem 2rem 15rem 2rem;
        font-size: 4rem;
        min-height: 78vh;
        background-size: auto 87%;
    }

    @include media-breakpoint-down(xs) {
        background-image: none;

        &__content {
            width: 90%;
        }
    }
}
