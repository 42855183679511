/* ===========================================
 * Bootstrap ext
 * ======================================== */
// Container
.container-fluid {
    max-width: 1280px;
}

.container-lg {
    max-width: 1230px;
    margin: auto;
}

.panel > .container-lg {
    padding-top: 2.2rem;
    padding-bottom: 2rem;
}

@include media-breakpoint-up(xl) {
    .container {
        max-width: 1394px;
        width: 100%;
    }
}

.container--small {
    max-width: 1175px;
    margin: auto;
}

// Typography
hr {
    margin-top: $spacer-y*2;
    margin-bottom: $spacer-y*1.5;
}

b,
strong {
    font-weight: 600;
}

// Forms
form {
    font-weight: normal;
    font-size: 14px;
}

.form-control {
    font-size: 14px;
    box-shadow: inset 0 1px 2px 0 rgba(137, 153, 205, 0.76);
}

.form-group > .btn {
    margin-bottom: -.7rem;
}

button,
input,
optgroup,
select,
textarea {
    font-family: inherit;
}

label {
    margin-bottom: 1rem;
    font-size: 14px;
    font-weight: normal;

    &:not(.custom-control) {
        //text-transform: uppercase;
    }
}

.panel > label {
    text-transform: uppercase;
}

.form-submit {
    text-align: right;
    margin-bottom: 1rem;
}

// Form > Custom control
.custom-control {
    min-height: 16px;
    margin-right: 7px;
}

.custom-control-indicator {
    top: 2px;
}

.custom-checkbox--panel {
    margin: 0;
    padding-left: 0;
    padding-right: $custom-control-gutter;
    display: block;

    .custom-control-indicator {
        left: auto;
        right: 0;
    }
}

// form-group
.input-group-addon {
    font-weight: 600;
    color: #79829f;
    max-width: 45%;
    white-space: normal;
    text-align: left;
    overflow: hidden;

    .custom-control-indicator {
        top: 1px;
    }
}

.input-group .form-control:not(:last-child) {
    border-right: none;
}

.input-group-addon {
    mix-blend-mode: multiply;
    background-color: #fafcff;
    box-shadow: inset 0 1px 2px 0 rgba(137, 153, 205, 0.76);

    label {
        margin-bottom: 0;
    }
}

.custom-control,
.custom-control-description {
    max-width: 100%;
}

.custom-control-indicator {
    border-radius: 2px;
    border: 1px solid #e8eaf0;
}

.custom-control--middle .custom-control-indicator {
    top: 50%;
    margin-top: -10px;
}

.custom-control-input:checked ~ .custom-control-indicator {
    border: none
}

.custom-control-description {
    img, i {
        margin-left: 5px;
    }
    i {
        color: #c3c6d9;
    }
}

/* Media */
.embed-responsive {
    > textarea {
        resize: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.embed-responsive-textarea {
    &::before {
        padding-top: 40%;
    }

    @include media-breakpoint-up(md) {
        &::before {
            padding-top: 28%;
        }

        textarea.embed-responsive-item {
            padding: 1.5rem 2rem;
        }
    }
}

.embed-responsive-textarea-small {
    &::before {
        padding-top: 30%;
    }

    @include media-breakpoint-up(lg) {
        &::before {
            padding-top: 17%;
        }
    }
}

/* Button */
.btn {
    border-radius: 0;
    text-transform: uppercase;
    font-weight: bold;
    box-shadow: 0 1px 1px 0 rgba(20, 78, 167, 0.27);
    font-size: 14px;
    letter-spacing: 0.5px;
    text-align: center;
    cursor: pointer;

    &-md {
        font-size: 13px;
        padding-top: 0.7rem;
        padding-bottom: 0.7rem;
    }

    &--no-border {
        box-shadow: none;
        border: none;
    }

    &--border {
        border: 1px solid #d1e4ff;
        @include link-variant(inherit, #ffffff);

        &:hover {
            border-color: $navy-blue-light;
        }
    }
}

.btn-cancel {
    box-shadow: none;
    @include button-variant($close-color, #ffffff, #ffffff);
}

.btn-navbar {
    @include button-variant(#ffffff, $navy-blue, #d1e4ff);
}

.btn-primary,
.btn-primary-light,
.btn-orange {
    &:focus {
        color: #ffffff;
    }
}

/* Navs */
.nav-tabs {
    display: flex;
    align-items: stretch;
    position: relative;
    z-index: 3;

    li {
        width: 255px;
    }

    .nav-link {
        @include transition(background-color $transition-speed, border-color $transition-speed);
        display: flex;
        justify-content: center;
        height: 100%;
        align-items: center;
        text-align: center;
        background-color: #e7e9f7;
        border-color: #dcdfe9;
        color: #7e879f;
        margin-right: 1px;
        font-weight: normal;
        position: relative;
        letter-spacing: 0.015rem;
        box-shadow: 0 0 4px 0 #d9e5f0;
        line-height: 1.2;

        &:before {
            @include transition(left $transition-speed, right $transition-speed);
            content: '';
            position: absolute;
            left: 50%;
            right: 50%;
            height: 2px;
            top: 0;
            background-color: #fe763a;
        }

        &.active {
            letter-spacing: normal;
            background-color: #fff;
            font-weight: 600;
            color: $color-dark;
            border-color: transparent;
            border: 1px solid rgba(black, .05);

            &:before {
                right: 2px;
                left: 2px;
            }

            &:after {
                content: '';
                position: absolute;
                left: 0px;
                right: 0;
                height: 4px;
                bottom: -3px;
                background-color: #ffffff;
            }
        }
    }
}

/* Hack for Safari - some height .nav-item*/
@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) {
    .nav-tabs .nav-item {
        height: 75px;
    }
}}


/* Alert */
.text-warning {
    color: #f6a623 !important;
}

.text-success {
    color: #a1c26b !important;
}

.alert {
    font-size: 14px;
    border: none;
    font-weight: normal;

    @include media-breakpoint-down(sm) {
        padding-left: 1rem;
        padding-right: 1rem;
    }
}

.alert-dismissible > i {
    position: relative;
    top: -$alert-padding-y / 1.5;
    right: -$alert-padding-x;
    padding: $alert-padding-y $alert-padding-x;
    color: inherit;
    float: right;
    font-size: 10px;
}

// popover
[data-toggle="popover"] {
    cursor: pointer;
}

label + [data-toggle="popover"] {
    vertical-align: middle;
    font-size: 16px;
}

.popover {
    border-radius: 0;
    color: #878faa;

    @include media-breakpoint-down(sm) {
        max-width: 70%;
    }
}

// Modal
.modal--no-title {

    @include media-breakpoint-down(xs) {
        .modal-dialog {
            margin-right: 2.5rem;
        }
    }

    .modal-header {
        padding: 0;

        .close {
            position: absolute;
            left: 100%;
            background: #ffffff;
            opacity: 1;
            width: 2rem;
            height: 2rem;
            line-height: 2rem;
        }

    }
}
.modal-inside {
    padding-left: $modal-inner-padding;
    padding-right: $modal-inner-padding;

    > * {
        max-width: 463px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 0;
        padding-right: 0;
    }

    .close {
        position: absolute;
        right: 1.85rem;
        top: 1.85rem;
    }

    .modal-title {
        padding-top: 1.8rem;
    }

    .modal-header {
        padding-bottom: .3rem;
    }

    .form-group {
        margin-bottom: 1.1rem;
    }
    label:not(.custom-control) {
        font-size: 1rem;
        margin-bottom: .4rem;
    }
}

.modal-header {
    display: block;
}

.modal-content {
    border-radius: 0;
    box-shadow: 0 0 2rem rgba(0, 0, 0, .5);
}

.modal-title {
    font-weight: 400;
    font-size: 1.75rem;
    clear: both;
}

.modal-footer {
    border-top: 1px solid #deecf8;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 1rem;
    text-align: center;

    a {
        @include link-variant($orange);
    }
}

// Progress bar
.progress-bar {
    border-radius: $progress-height;
}