// Custom 

// Bootstrap 
// Options
//$enable-rounded: false;

$transition-speed: .3s;
// Fonts
$font-family-sans-serif: 'Source Sans Pro', sans-serif;
$font-family-serif: 'Damion', cursive;
$headings-font-weight: 400;
$line-height-base: 1.45;
$headings-margin-bottom: 1.3rem;

// Colors
$blue: #1294ff;
$navy-blue: #353d59;
$navy-blue-light: #707eb0;
$state-success-bg: #a1cf73;
$state-success-text: #fff;
$body-color: #5a6d7f;
$text-color: $navy-blue;
$orange: #ff9261;
$body-bg: #f6f7fb;
$gray-lighter: #f9f9fc;
$gray-light: #c3c6d9;
$brand-success: #b3df79;
$headings-color: $text-color;
$color-light: #a7acbd;
$color-dark: #2e374e;
// Form
$border-radius: 4px;
$custom-control-indicator-bg: #f6f7fb;
$custom-control-focus-indicator-box-shadow: 0 0 0 1px $body-bg, 0 0 0 2px $blue;
$input-padding-x: 1.2rem;
$input-padding-y: .9rem;
$input-bg: #fafcff;
$input-border-color: #e7f1ff;
$custom-checkbox-radius: 2px;
$form-group-margin-bottom: 1.3rem;
$btn-padding-y-lg: 1rem;
$custom-control-gutter: 1.7rem;
$input-color-placeholder: #9ea3b5;

// Buttons 
$btn-padding-x-lg:               2.5rem ;
$btn-padding-x-sm:               .8rem ;
$btn-padding-y-sm:               .95rem ;

// Navs 
$nav-tabs-border-color: transparent;
$nav-link-padding: 1.1rem .5rem;

// Table 
$table-cell-padding : .7rem 1.2rem;

// Alerts 
$alert-padding-x:             2rem !default;
$alert-padding-y:             1.1rem ;
$alert-border-radius: 2px;

// Swal
$swal2-info: #fe763a;
$swal2-overlay: rgba(53, 61, 89, 0.69);

$swal2-success: $brand-success;
$swal2-success-border: rgba($swal2-success, .8);
// Progress bars
$stepper-color-active: #e59465;
$progress-height:               .5rem;
$progress-bar-bg:              $stepper-color-active;

// popover
$popover-bg: $body-bg;
$popover-border-width: 0;
$popover-max-width: 447px;
$popover-arrow-outer-color: $body-bg;
$popover-arrow-outer-width: 10px;
$popover-inner-padding: 1.5rem;

// Modal
$modal-header-padding: 1.5rem;
$modal-inner-padding: $modal-header-padding;
$modal-md: 620px;
$close-color: #c1c7db;
$close-font-size: 2rem;
$close-font-weight: 300;
$modal-header-border-width: 0;
$modal-backdrop-bg: rgb(53, 61, 89);
$modal-backdrop-opacity: 0.69;

// Tooltip
$tooltip-max-width: 520px;
$tooltip-bg: $body-bg;
$tooltip-padding-y: .5rem;
$tooltip-padding-x: $tooltip-padding-y;
// Patch 
$icomoon-font-path: "../fonts/icomoon/fonts";