.package-list {
    display: flex;
    flex-wrap: wrap;
    margin: 1rem -.5rem;
    align-items: stretch;

    @include media-breakpoint-down(md) {
        justify-content: center;
    }
}

.package {
    $this: &;
    @extend %panel;
    padding: 1.2rem 0;
    width: 190px;
    margin: .5rem;
    text-align: center;
    min-width: 150px;
    display: flex;
    flex-direction: column;
    line-height: 1.1;

    >  * {
        padding-left: 1.2rem;
        padding-right: 1.2rem;
    }

    @include media-breakpoint-down(md) {
        width: calc(50% - 1rem);
    }

    &__icon {
        margin-top: .6rem;
        margin-bottom: .8rem;
    }

    &#{$this}--icon-top {
        margin-top: 2rem;

        #{$this}__icon {
            margin-top: -3rem;
            height: 70px;
        }
    }

    &__title {
        font-size: 1.75rem;
        font-weight: 600;
        margin-bottom: .5rem;
        
        &__small {
            font-size: 1.25rem;
            margin-bottom: .25rem;
        }
    }

    &__action {
        padding-top: 1rem;
        margin-top: auto;
    }

    &__info {
        font-size: 0.9375rem;
        color: $orange;
        font-weight: normal;
        margin-bottom: .3rem;
    }

    &__price {
        color: $brand-primary;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 1rem;
        white-space: nowrap;
    }

    @include media-breakpoint-down(xs) {
        flex-direction: row;
        width: 100%;
        justify-content: space-around;
        align-items: center;

        > * {
            margin: 0;
        }
    }
}

.package--inline {
    $this: '.package';
    @extend %panel-disable;
    padding: 0;
    text-align: left;
    display: block;

    .inside {
        display: flex;
        align-items: center;
        padding: 0;
        margin: 0 -5px;

        > * {
            margin: 0;
            padding: 0 5px;
        }
    }

    img {
        max-width: 32px;
    }

    #{$this}__title {
        font-size: 1.5rem;
        margin: 0;
    }
    #{$this}__info {
        font-size: 13px;
        margin: 0;
    }
}