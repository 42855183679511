.nav-aside {
    font-weight: normal;
    ul, li {
        @extend %reset;
    }

    li {
        margin-bottom: .6rem;
    }

    a {
        @include link-variant($color-dark);
        @include icon(after, '\e909');
        @extend %panel;

        display: block;
        padding: 1.1rem 2.2rem 1.1rem 3.4rem;
        line-height: 1.15;
        position: relative;

        &.active,
        &:hover {
            background-color: $navy-blue;
            color: #ffffff;

            i {
                color: inherit;
            }
        }

        &:after {
            position: absolute;
            right: 1rem;
            color: #d1e4ff;
            font-size: 12px;
            top: 1.3rem;
        }
    }

    i {
        position: absolute;
        color: $orange;
        width: 1.5rem;
        text-align: center;
        left: 1rem;
    }
}

@include media-breakpoint-down(sm) {
    $nav-width: 280px;
    body {
        @include transition(margin .3s);
    }

    .nav-aside-outside {
        @include transition(left .3s);
        position: fixed;
        left: -$nav-width;
        width: $nav-width;
        top: 0;
        bottom: 0;
        z-index: 99;
        background: $body-bg;
        overflow: hidden;

        &__close {
            @include link-variant($brand-primary);
            background: $body-bg;
            position: absolute;
            left: 100%;
            top: 0;
            padding: 1.15rem;
            line-height: 1;
            z-index: 9;
            box-shadow: 2px 2px 5px rgba(0,0,0,.2);
        }

        nav {
            li {
                margin-bottom: 0;
            }

            a {
                box-shadow: none;
                background: transparent;
                border-image: none;
                border-bottom: 1px solid darken($body-bg, 5%);
            }
        }
    }

    .is-navigation-aside {
        overflow: hidden;
        margin-left: $nav-width;
        margin-right: -$nav-width;

        #header,
        #main,
        #footer {
            opacity: .2;
        }

        .nav-aside-outside {
            left: 0;
            box-shadow: 0 0 10px rgba(0,0,0,.5);
            overflow: visible;

            nav {
                height: 100%;
                overflow-y: auto;
            }
        }
    }
}

