.table {
    font-weight: normal;

    th {
        color: $color-dark;
        font-weight: 600;
        font-size: 1rem;
    }

    thead th {
        vertical-align: top;
        line-height: 1.4;
        padding-bottom: 3px;
    }

    td.bg-light {
        border-left: none !important;

        + td {
            border-left: none;
        }
    }
}

table tr .th-light {
    font-weight: normal;
    text-align: left;
    color: $color-light;
    padding-left:0;
    font-size: 14px;
}

/* Table - Panel */
.table-responsive {
    margin-left: -5px;
    margin-right: -5px;
    padding-left: 5px;
    overflow-y: hidden;
    margin-bottom: 1rem;
    width: auto;

    > * {
        border-right: 5px solid transparent;
    }

    &--panel {
        @extend %panel;
    }
}

.table-panel {
    background-color: #fff;
    border-collapse: separate;
    border-spacing: 0 1rem;
    font-size: 14px;
    color: #79829f;
    margin-bottom: -.8rem;
    margin-top: -.8rem;
    position: relative;

    thead {
        th, td {
            border: none;
        }
    }

    tfoot td {
        border-top-color: #d1e4ff;
        border-left: none;
        font-size: 1rem;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    tbody tr {
        td {
            height: 52px;
            @extend %panel;
            position: relative;

            &:not(:last-child) {
                border-right: none;
            }

            &:before {
                content: '';
                position: absolute;
                left: -1px;
                top: 0;
                bottom: 0;
                width: 2px;
                background: #ffffff;
            }
        }
    }

    td, th {
        text-align: center;
    }

    td {
        vertical-align: middle;
        border-left: 1px solid #eef3f9;
    }

    label {
        margin-bottom: 0;
    }
}

.table-panel--responsive {
    margin:  0 0 2rem 0;
    border-spacing: 0 .8rem;

    td {
        padding: 1.5rem 2rem;
        line-height: 1.3;
    }

    .package__price {
        margin-bottom: 0;
    }

    .package--inline {
        margin-left: -.7rem;
        margin-right: -1.8rem;
    }
}

@include media-breakpoint-down(lg) {
    .table-panel--responsive {
        display: block;
        margin-top: 2rem;

        .package--inline {
            margin: 0;
        }

        thead, tbody, th, td, tr {
            display: inherit;
            text-align: left;
        }

        thead tr {
            position: absolute;
            top: -9999px;
            left: -9999px;
        }

        tbody tr {
            &:not(:last-child) {
                margin-bottom: 1rem;
            }

            td {
                height: auto;
                border: solid #eee;
                border-width: 0 1px 1px 1px;
                position: relative;
                padding: 3rem 1rem 1rem 1rem;
                border-radius: 0;
                //padding-left: 35%;

                &:after {
                    content: attr(data-label);
                    position: absolute;
                    top: 1rem;
                    left: 1rem;
                    padding-right: 10px;
                    color: $color-light;
                    font-weight: normal;
                }
            }
        }
    }
}

.file-row {
    &__name {
        min-width: 250px;
        font-size: 14px;
        color: $navy-blue;
        font-weight: normal;
        line-height: 1.2;

        max-width: 200px;
    }

    .file__name-text {
        @include ellipsis();
        display: block;
    }
}

table .td-id {
    @extend .bg-light;
    width: 63px !important;
    max-width: 63px;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
}

.td-logo {
    width: 115px;
    min-width: 90px;
}

.table-summary {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-weight: normal;

    &__transfer {
        width: 125px;
        display: inline-block;
        color: $orange;
        text-align: center;
        font-size: 1.5rem;
        font-weight: 600;
        position: relative;
        top: -.2rem
    }
}

.panel-tabled {
    @extend %panel;
    border-radius: 4px;
    overflow: hidden;
    padding-bottom: 5px;
    margin-bottom: 1rem;

    > * {
        margin-bottom: 0;
    }

    .table-panel {
        margin-top: 0;
    }
}

.table-panel--server {
    //@extend %panel;
    border-spacing: 0;

    tbody tr {
        td {
            @extend %panel-disable;
        }
    }
}

