.pagination {
    text-align: center;
    line-height: 1;
    font-weight: 600;
    padding: 1.3rem 0 1rem 0;


    ul, li {
        @extend %reset
    }

    li {
        display: inline-block;
        font-size: 12px;
        text-transform: uppercase;
        padding: 0 8px;

        a {
            display: inline-block;
            padding: 5px 9px;
            text-decoration: none;
            @include link-variant(#83a0c5);
        }

        i {
            font-size: 11px;
            margin: 0 6px;
        }

        &:not([class^="pagination"]) {
            padding: 0 4px;
            font-size: 14px;

            a {
                background-color: #d1e4ff;
                border-radius: 4px;
                @include link-variant(#95acca, #ffffff);

                &:hover,
                &.active {
                    background-color: #8ea9ce;
                    color: #ffffff;
                }
            }
        }
    }

    @include media-breakpoint-down(xs) {
        margin-left: -15px;
        margin-right: -15px;

        li {
            padding: 0;
            margin: 0 ;

            i {
                margin: 0;
            }

            a {
                padding-left: 8px;
                padding-right: 8px;
            }
        }
    }
}