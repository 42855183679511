.jwplayer {
    $padding-container: 1.5rem;

    // Control container
    .jw-controlbar {
        padding-left: $padding-container;
        padding-right: $padding-container;
        padding: $padding-container;
        background-color: transparent;
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);

    }

    .jw-group {
        vertical-align: middle;
    }
    // Progress
    .jw-controlbar-center-group {
        //position: absolute;
        //bottom: 100%;
        //left: $padding-container;
        //right: $padding-container;
    }

    .jw-slider-time,
    .jw-slider-container {
        height: 8px;
    }

    .jw-slider-container {
        .jw-knob {
            width: 18px;
            height: 18px;
            background-color: #4f7dd7;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.23);
            border-radius: 50%;
            top: 50%;
            margin-top: -9px;
        }
    }

    .jw-slider-time,
    .jw-progress,
    .jw-buffer {
        border-radius: 8px;
    }
    .jw-progress {
        background: rgba(#1294ff, .54);
    }
    .jw-buffer {
        background: rgba(#ffffff, .12);
    }
    .jw-slider-horizontal .jw-buffer, .jw-slider-horizontal .jw-progress, .jw-slider-horizontal .jw-rail {
        height: 8px;
    }

    // Control right container
    .jw-controlbar-right-group {
        text-align: right;
    }

    .jw-icon-inline, .jw-icon-tooltip, .jw-slider-horizontal, .jw-text-countdown, .jw-text-duration, .jw-text-elapsed {
        padding: 0 1rem;
    }
}

