// footer styles

/* ===========================================
 * Footer > Container
 * ======================================== */
.footer-container {
    $footer-link-hover: #3585ff;
    padding: 7% 0;
    background-color: $navy-blue;
    color: #c1c7db;
    line-height: 1.4;

    p {
        margin-bottom: 1.3rem;
        &.small-space {
            margin-bottom: 0.75rem;
        }
    }

    a {
        @include link-variant(inherit, $footer-link-hover)
    }

    strong,
    b {
        a {
            @include link-variant($footer-link-hover, inherit);
        }
    }

    .col {
        padding-bottom: 2rem;

        @include media-breakpoint-down(sm) {
            min-width: 50%;
        }

        @include media-breakpoint-up(lg) {
            &:nth-child(1) {
                flex: 0 0 30.7%;
                max-width: 30.7%;
            }
            &:nth-child(2) {
                flex: 0 0 28.2%;
                max-width: 28.2%;
            }
            &:nth-child(4) {
                flex: 0 0 24.6%;
                max-width: 24.6%;
            }
        }
    }
}

/**
 * Footer > Logo
 */
.footer-logo {
    font-family: $font-family-serif;
    font-size: 2rem;
    margin-bottom: 2rem;
    padding-top: .5rem;

    a {
        color: #fff;
        text-decoration: none;
    }

    img {
        display: block;
    }
}

/**
 * Footer > Navigation
 */
.footer-navigation {
    ul, li {
        @extend %reset;
    }

    li {
        margin-bottom: 1.3rem;
    }
}

/**
 * Footer > Headline
 */
.footer-headline {
    color: #ffffff;
    text-transform: uppercase;
    font-weight: 600;
    padding-top: 1rem;
    margin-bottom: 1.8rem;
}

/**
 * Footer > Copyright
 */
.footer-copyright {
    text-align: center;

    @include media-breakpoint-up(lg) {
        position: absolute;
        left: 15px;
        bottom: 1rem;
        max-width: 25%;
        text-align: left;
        margin-bottom: 10px;
    }
}

/**
 * Footer > Social list
 */
.list-social {
    @extend %reset;
    display: flex;
    justify-content: space-between;
    max-width: 235px;
    width: 100%;
    font-size: 1.4rem;
    margin-left: -5px;
    padding-top: 1rem;

    li {
        padding: 0 5px;
    }

    @include media-breakpoint-up(lg) {
        position: absolute;
        bottom: 2.3rem;
        margin-bottom: 1rem;
    }
}
