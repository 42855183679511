// Swal ext
.swal2-modal {
    border-radius: 0;
    font-family: inherit;
    padding: 1.2rem 2rem 2rem 2rem !important;

    .swal2-title {
        font-weight: 400;
        font-size: 1.75rem;
        margin-bottom: 1.2rem;
    }

    .swal2-content {
        font-size: 14px;
        color: #878faa;
        font-weight: 400;
        line-height: 1.5;
    }

    .swal2-buttonswrapper {
        margin-top: 20px;
        .btn {
            margin: 0 .5rem;
            min-width: 100px;
        }
    }
}
.swal2-icon {


    &.swal2-success .swal2-success-ring {

    }

    &:not(.swal2-success) {
        transform: scale(.32);
        margin-bottom: -1.5rem;
        margin-top: -1.5rem;
        font-weight: bold;
        border-width: 10px;
    }

    &.swal2-info {
        border-color: $swal2-info;
    }
}
