%reset {
    margin: 0;
    padding: 0;
    list-style: none;
    background: transparent;
    border: none;
}

.reset {
    @extend %reset;
}

%disable-user-select {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Colors */
.color-orange {
    color: $orange;
}

.color-text {
    color: $text-color;
}

.color-light {
    color: $color-light;
}

.color-dark {
    color: $color-dark;
}

/* Panel */
@mixin panel() {
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 #d9e5f0;
    position: relative;
    z-index: 2;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
    border-image-slice: 1;
}

%panel {
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 0 4px 0 #d9e5f0;
    position: relative;
    z-index: 2;
    border-width: 1px;
    border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
    border-image-slice: 1;
}


%panel-disable {
    border-radius: 0;
    box-shadow: none;
    border-color: transparent;
}


.panel {
    @extend %panel;
    padding: 1.4rem 15px;
    margin-bottom: .6rem;

    &__headline {
        font-size: 1.75rem;
        font-weight: normal;
        line-height: 1.3;
        color: #353d59;
    }

    &__subheadline {
        font-size: 14px;
        margin-bottom: 7px;
        color: $color-light;
        font-weight: normal;
    }

    @include media-breakpoint-up(sm) {
        padding-left: 1.7rem;
        padding-right: 1.7rem;
    }
}

.panel__headline-container {
    margin-bottom: 2.5rem;
}

.panel__headline--lg {
    font-size: 3rem;
    margin-bottom: .5rem;
    line-height: 1.1;
}

.panel__subheadline--lg {
    font-size: 1rem;
    margin-bottom: 1rem;
}

.panel-md {
    @extend %panel;
    padding: 1.1rem 15px;
    font-weight: normal;
    font-size: 14px;
    margin-bottom: .6rem;

    @include media-breakpoint-up(sm) {
        padding-left: 1.7rem;
        padding-right: 1.7rem;
    }
}

.panel .panel-md {
    border-radius: 4px;

    &:not(:last-child) {
        margin-bottom: 1rem;
    }
}


.panel-text {
    font-weight: normal;
    font-size: 14px;
    padding-bottom: 1rem;

    p {
        margin-bottom: 1.25rem;
    }
}

.panel-label {
    display: block;
    margin-bottom: 0;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: normal;
    letter-spacing: 0.2px;
}

/* Background */
.bg-light {
    background-color: #f7f8fb !important;

    &:after,
    &:before {
        display: none;
    }
}

/* Buttons */
.btn {
    white-space: normal;
}

.button-group {
    margin-left: -.5rem;
    margin-right: -.5rem;

    .btn {
        margin: .5rem;
    }
}

.btn-primary.disabled {
    background-color: #d1e3ff;
    border-color: #d1e3ff;
}

.btn-primary-light {
    @include button-variant($btn-primary-color, #d1e3ff, #d1e3ff);
}

.btn-orange {
    @include button-variant(#ffffff, #ff8e5c, #ff8e5c);
}

.btn-link {
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 0.2px;
}

.link-back {
    @include icon(before, '\f104', 8px);
    @include link-variant($brand-primary);
    font-size: 14px;
    letter-spacing: 0.4px;
    font-weight: normal;
    line-height: 1;
    &:before {
        position: relative;
        top: 2px;
        font-size: 1.2em;
    }
}

.link-orange {
    @include link-variant($orange)
}

.link-inherit {
    @include link-variant(inherit)

}

.link-light {
    @include link-variant($color-light);
}

.btn-big {
    padding: $btn-padding-y-sm  1rem;

}
/* Margin / Padding / Sizes */
.mb--5 {
    margin-bottom: -5rem;
}

.w-70-pr {
    width: 70%;
}

.w-30-pr {
    width: 30%;
}

.w-85-px {
    width: 85px;
}

.w-a {
    width: auto !important;
}

.w-1 {
    width: 1px;
}

.mw-1 {
    width: 1px;
}

.mw-60 {
    min-width: 90px;
}


.maw-650-px {
    max-width: 650px;
}

@include media-breakpoint-up(lg) {
    .maw-lg-77-pr {
        max-width: 77%;
    }
}


.border-l-n {
    border: none;
    box-shadow: none;
    position: relative;

    &:before {
        width: 3px !important;
        left: auto !important;
        right: 100%;
    }
}

/* Text */
.text-orange {
    color: $orange;
}

.word-break {
    word-break: break-all;
}

.text-small {
    font-size: 12px;
    color: #878faa;
}

.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-600 {
    font-weight: 600;
}
/* Grid */
@include media-breakpoint-up(lg) {
    .gutters-lg-30 {
        margin-left: -30px;
        margin-right: -30px;

        > * {
            padding-left: 30px;
            padding-right: 30px;
        }
    }
}

/* Mask */
.mask {
    position: absolute;
    display: flex;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, .95);
    z-index: 2;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.075rem;
    text-align: center;
    color: #1294ff;
    text-transform: uppercase;
    flex-direction: column;
    padding: 1rem;
}

.loader {
    $time: 1000;
    $items: 7;
    display: flex;
    width: 70%;
    max-width: 230px;
    padding: 1rem 0;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;

    &__item {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: $brand-primary;

        animation: #{$time}ms loaderFadeOut infinite;

        //loop through to add an animation delay
        @for $i from 1 through 7 {
            &:nth-child(#{$i}) {
                animation-delay: ($i * $time/$items) + ms;
            }
        }
    }
}

@keyframes loaderFadeOut {
    0% {
        opacity: 1
    }
    100% {
        opacity: .1
    }
}

/* Visible */
@include media-breakpoint-only(sm) {
    .hidden-sm {
        display: none !important;
    }
}

.position-static {
    position: static;
}

/* Max height */
.height-responsive {
    max-height: 700px;
    overflow-y: auto !important;

    &.table-responsive {
        padding-bottom: .8rem;
    }
}

