/* ===========================================
 * Steps
 * ======================================== */
.steps {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-top: 1rem;
    margin-bottom: 1.7rem;
    padding-left: .5rem;
    padding-right: .5rem;
    font-weight: normal;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        display: block;
        width: 100%;
        text-align: justify;
        &:after {
            content: "";
            margin-left: 100%;
            display: inline-block;
        }

        &__item {
            display: inline-block;
        }
    }

    &__progress {
        position: absolute;
        left: 2rem;
        right: 2rem;
        top: 1rem;
        background: #f9f9fc;
        height: 7px;
    }

    &__item {
        $self: &;
        text-align: center;

        a {
            @include link-variant(inherit);
        }

        &-icon {
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 0 auto .5rem auto;
            position: relative;
            color: $gray-light;
            background-color: $gray-lighter;
            border-radius: 50%;
            font-size: 1.3rem;

            .icon-cloud-tl {
                font-size: .7em;
            }
        }

        &.active,
        &.trail {
            color: #fe763a;
            #{$self}-icon {
                background-color: $stepper-color-active;
                color: #ffffff;
            }
        }

        &.active {
            #{$self}-icon {
                &:after {
                    animation: stepsActiveAnimation 2s infinite;
                    content: '';
                    left: -10px;
                    right: -10px;
                    top: -10px;
                    bottom: -10px;
                    background-color: #f0c7b0;
                    position: absolute;
                    z-index: -1;
                    border-radius: 50%;
                    border: 5px solid #f9eae1;
                }
            }
        }
    }
}

@keyframes stepsActiveAnimation {
    from {
        transform: scale(1);
    }

    50% {
        transform: scale(0.8);
    }

    to {
        transform: scale(1);
    }
}
