/* Mixins */
@mixin ellipsis ($max-width: 100%){
    display: inline-block;
    max-width: $max-width;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

@mixin icon($position: before, $icon: '', $margin: '', $color: '') {
    &:#{$position} {
        @if $icon {
            content: $icon;
            font-family: 'icomoon' !important;
            speak: none;
            font-style: normal;
            font-weight: normal;
            font-variant: normal;
            text-transform: none;
            line-height: 1;

            /* Better Font Rendering =========== */
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
        }

        @if $margin {
            @if ($position == before) {
                margin-right: $margin;
            }
            @if ($position == after) {
                margin-left: $margin;
            }
        }

        @if $color {
            color: $color;
        }
    }
}

@mixin link-mask() {
    @include indentText();
    position: absolute;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
}

@mixin link-variant($color, $hovercolor: null,  $textdecoration: null) {
    @if ($color) {
        color: $color;
    } @else {
        color: inherit;
    }

    @if ($hovercolor) {
        &:hover {
            color: $hovercolor;
        }
    } @else {

        @if ($color == inherit) {
            &:hover {
                opacity: .8;
            }
        } @else {
            &:hover {
                color: darken($color, 20%);
            }
        }
    }

    @if ($textdecoration) {
        &:hover,
        &:focus,
        &:active {
            text-decoration: $textdecoration;
        }
    } @else {
        &:hover,
        &:focus,
        &:active {
            text-decoration: none;
        }
    }
}
