.payment-method {
    $this: &;
    width: 11.5rem;
    text-align: center;

    margin: 0;
    padding: 0;

    &__image,
    &__label,
    .custom-control-description {
        display: block;
        width: 100%;
    }

    &__label {
        line-height: 1.15;
        min-height: 2rem;
        color: $color-dark;
    }

    &__image {
        @extend %panel;
        border-radius: 4px;
        text-align: center;
        height: 6.5rem;
        line-height: 6.5rem;
        margin-bottom: .8rem;
        padding: 0 1rem;

        img {
            max-width: 100%;
            max-height: 70%;
            margin: 0;
        }
    }

    // Custom control
    .custom-control-indicator {
        z-index: 5;
        right: 5px;
        top: 5px;
        left: auto;
        border-color: #e3e6ef;
        width: 1.6rem;
        height: 1.6rem;
        line-height: 1.6rem;
        background: transparent;
        color: #e3e6ef;
        font-size: .7rem;
        @include icon(before, '\e90b');

        &:before {
            line-height: inherit;
        }
    }

    input:checked ~ .custom-control-indicator {
        background: #aac175 !important;
        border: 1px solid;
    }

    input:checked ~ .custom-control-description {
        #{$this}__image {
            box-shadow: 0 0 4px 0 rgba(161, 194, 107, 0.86);
            border-image-source: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.1) 5%, rgba(255, 255, 255, 0) 20%, rgba(255, 255, 255, 0));
            border-image-slice: 1;
        }
    }

    input:focus ~ .custom-control-indicator {
        box-shadow: 0 0 0 1px #f6f7fb, 0 0 0 2px #aac175
    }

}

.list-payment-method {
    display: flex;
    flex-wrap: wrap;
    margin: .5rem -.8rem 3rem -.8rem;

    > * {
        margin: .9rem .8rem;
        max-width: calc(50% - 2rem);
    }
}


.list-payment-method--sm {
    margin-left: -.6rem;
    margin-right: -.6rem;
    margin-bottom: 2rem;
    margin-top: 1rem;

    .payment-method {
        $this: '.payment-method';
        width: 8.75rem;
        margin: .5rem .6rem;
        max-width: calc(50% - 1.2rem);

        #{$this}__image {
            height: 4.8125rem;
            line-height: 4.8125rem;
        }
    }

}